/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    🔥 <strong>Us presentem un curs únic i REVOLUCIONARI.</strong>
                </p>

                <p>
                    📢 Un curs que combina totes les notícies d'actualitat setmanals i la cultura
                    general.
                </p>

                <p>
                    📝 En el moment de matricular-vos al curs, tindreu accés al mes anterior de
                    notícies i als dos posteriors a la matriculació.
                </p>

                <p>
                    🎯 Un curs creat per a totes les convocatòries que venen a partir de gener de
                    2025.
                </p>

                <p>
                    💡 Sabem que la <strong>CULTURA GENERAL</strong> és infinita i complicada de
                    preparar.
                </p>

                <p>
                    📰 I també sabem que recopilar dia a dia les notícies d'actualitat és molt
                    complicat.
                </p>

                <p>
                    📚 En aquest curs, us presentem una infinitat de temes diferents de cultura
                    general i us ensenyem com aplicar diverses estratègies de memòria per recordar
                    noms, dates i dades d'interès que us poden aparèixer en els vostres exàmens.
                </p>

                <p>
                    🎥 Cada tema de cultura general compta amb un vídeo d'uns 20 minuts i
                    s'acompanya d'un test. A més a més, trobareu simulacres d'examen a la plataforma
                    online.
                </p>

                <p>
                    📆 <strong>Actualització constant:</strong> Cada setmana anirem afegint totes
                    les notícies destacades i pujarem infinitat de tests per posar-vos a prova.
                </p>

                <p>
                    🎬 I per rematar, un vídeo explicatiu de 20 minuts amb totes les notícies més
                    importants.
                </p>

                <p>
                    🏆 És un molt bon inici per afrontar amb garanties els vostres exàmens de
                    cultura i actualitat, així que, com sempre, només em queda recomanar-vos el
                    curs.
                </p>

                <p>
                    💰 <strong>Per només 34,99 €</strong>, tindreu accés a <strong>TOTS</strong>{" "}
                    aquests continguts des del moment que us matriculeu.
                </p>

                <p>
                    🚀{" "}
                    <strong>
                        <a href="#">Apunta't ara!</a>
                    </strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="L'únic curs que et manté al dia amb l’actualitat i et prepara per triomfar als exàmens de cultura general! 🔥"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
